@import 'colors';

@mixin border-radius($radius){
    -moz-border-radius:   $radius;
    -webkit-border-radius:$radius;
    border-radius:        $radius;
}
/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
    margin: 0;
    padding: 0;
}



/**
 * Basic styling
 */
body {
    font-family: $base-font-family;
    font-size: $base-font-size;
    line-height: $base-line-height;
    font-weight: 300;
    color: $text-color;
    background-color: $background-color;
    -webkit-text-size-adjust: 100%;
}



/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
    margin-bottom: $spacing-unit / 2;
}



/**
 * Images
 */
img {
    max-width: 100%;
    vertical-align: middle;
}



/**
 * Figures
 */
figure > img {
    display: block;
}

figcaption {
    font-size: $small-font-size;
}



/**
 * Lists
 */
ul, ol {
    margin-left: $spacing-unit;
}

li {
    > ul,
    > ol {
         margin-bottom: 0;
    }
}



/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
}



/**
 * Links
 */
a {
    color: $brand-color;
    text-decoration: none;

    &:visited {
        color: darken($brand-color, 15%);
    }

    &:hover {
        color: $text-color;
        text-decoration: underline;
    }
}



/**
 * Blockquotes
 */
blockquote {
    color: $grey-color;
    border-left: 4px solid $grey-color-light;
    padding-left: $spacing-unit / 2;
    font-size: 18px;
    letter-spacing: -1px;
    font-style: italic;

    > :last-child {
        margin-bottom: 0;
    }
}



/**
 * Code formatting
 */
pre,
code {
    font-size: 15px;
    border: 1px solid $grey-color-light;
    border-radius: 3px;
    background-color: #eef;
}

code {
    padding: 1px 5px;
}

pre {
    padding: 8px 12px;
    overflow-x: scroll;

    > code {
        border: 0;
        padding-right: 0;
        padding-left: 0;
    }
}



/**
 * Wrapper
 */
.wrapper {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
    max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
    margin-right: auto;
    margin-left: auto;
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
    background-color: $centerColor;
    @extend %clearfix;

    @include media-query($on-laptop) {
        max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
        max-width:         calc(#{$content-width} - (#{$spacing-unit}));
        padding-right: $spacing-unit / 2;
        padding-left: $spacing-unit / 2;
    }
}

.bodyWrapper{
    @extend .wrapper;
    padding-top:  $spacing-unit;
}

.hfWrapper{
    @extend .wrapper;
    background-color: $hfColor
}
/**
 * Clearfix
 */
%clearfix {

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}



/**
 * Icons
 */
.icon {

    > svg {
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;

        path {
            fill: $grey-color;
        }
    }
}

.pdfBox{
    position: relative;
    width: 100%;     /* desired width */
}
.pdfBox:before{
    content: "";
    display: block;
    padding-top: 129.4%;  /* initial ratio of 1:1*/
}

.pdfContent{
    position:  absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.wideMediaBox{
    position: relative;
    width: 100%;     /* desired width */
}
.wideMediaBox:before{
    content: "";
    display: block;
    padding-top: 56.25%;  /* initial ratio of 1:1*/
}

.widePP{
    position: relative;
    width: 100%;     /* desired width */
}
.widePP:before{
    content: "";
    display: block;
    padding-top: 60%;  /* if no calc block support*/
    padding-top: calc(56.26% + 23px); /* account for the little button bar */
}

.fourThreeBox{
    position: relative;
    width: 100%;     /* desired width */
}
.fourThreeBox:before{
    content: "";
    display: block;
    padding-top: 75%/* account for the little button bar */
}

.nineSixBox{
    position: relative;
    width: 50%;     /* desired width */
    text-align: center;
    display:block;
}
.nineSixBox:before{
    content: "";
    display: block;
    padding-top: 177.7777777777778%/* account for the little button bar */
}

.mediaContent{
    position:  absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.medPic{
  max-height: 200px;
  width: auto;
  width: auto\9; /* for ie8*/
  @include border-radius(25px);
  padding:5px;
  text-align: center;
}

.largePic{
    @extend .medPic;
    max-height: 400px;
}

.center {
  text-align: center;
}

.postTableRow{
    vertical-align: middle;
    padding-right: 20px;
    border-bottom: 3px solid rgb(220,220,220);
}
.postTableDate{
    @extend .postTableRow;
    white-space:nowrap;
}

table{
    border-collapse: collapse;
    border-spacing: 3;
    // border:4px solid #000000;
}

td, th{
    // border:2px solid #000000;
    padding:2px;
    padding-left: 5px;
    padding-right: 5px;
}

th{
    text-align: center;
}

.filter-type {
}

.sidebarlistscroll {
    width: 100%;
    height: 100%;
    margin-bottom: 15px;
    overflow-y: none;
    border: none;
    visibility: hidden;
}
#filter{display:none}
#filter:checked + .sidebarlistscroll{
    visibility: visible;
}

.wrap {
  width: 500px;
  padding: 10px 20px;

}

li.tag {
  display: inline-block;
  width:auto;
  height: auto;
  background: $blockColor;
  list-style: none;
  @include border-radius(7px);

  font-size: 14pt;
  color: $blockFontColor;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 2px;
  padding-top: 2px;
  margin: 1px;
}
li.tag:hover{
    background: $blockActiverColor
}

ul.tag {
  background: $containerColor;
  margin: 4px; padding: 10px;
  @include border-radius(30px);

}

a.tag{
    color: $blockFontColor
}

a.tag:hover{text-decoration: none}

// Borrowed From http://www.realcombiz.com/2014/01/how-to-expand-collapse-toggle-div-layer.html
.toggle-box {
  display: none;
}

.toggle-box + label {
  cursor: pointer;
  display: block;
  font-weight: bold;
  line-height: 21px;
  margin-bottom: 5px;
}

.toggle-box + label + div {
  display: none;
  margin-bottom: 10px;
}

.toggle-box:checked + label + div {
  display: block;
}

.toggle-box + label:before {
  background-color: #4F5150;
  @include border-radius(10px);
  color: #FFFFFF;
  content: "+";
  display: block;
  float: left;
  font-weight: bold;
  height: 20px;
  line-height: 20px;
  margin-right: 5px;
  text-align: center;
  width: 20px;
}

.toggle-box:checked + label:before {
  content: "\2212";
}
// end borrowed section
.postContainer{
    vertical-align: middle;
    text-align: center;
}

.postBox:hover{
    text-decoration: none;
}
div.postBox{
    width: 90%;
    display: inline-table;
    background: $blockColor;
    @include border-radius(10px);
    padding:10px;
    margin:3px;
    color: $blockFontColor;
    vertical-align: middle
}
div.postBox:hover{
    background: $blockActiverColor;
}

img.postBox{
    @include border-radius(5px);
    width: auto;
    max-width: 275px;
    height: auto;
    max-height: 200px;
    border: 10px;
    display: inline-block;
    vertical-align: top;
    text-align: left;
}

.dateSumBox{
    min-width: 175px;
max-width: 300px;
display: inline-block;
margin: 10px;
vertical-align: top;
}
.postBoxDate{
    text-align: center;
    font-size: 10pt;
    margin-bottom: 4px;
    margin-top: 4px;
}
.postBoxTitle{
    text-align: center;
    font-size: 16pt;
}

.lameLink:hover{text-decoration: none}

hr{
    border: 0;
height: 3px;
background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.75), rgba(0, 0, 0, 0));
}

.calListItem{
    font-size: 16pt;
}

/**
 * Specials for home page
 */
.big-home {
    width:60%;
    flex-grow:1;
    margin: 5px;
    p{
        font-size: 22px;

        @include media-query($on-laptop) {
            font-size: 18px;
        }
    }
}

.home-container {
    display:flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.headshot-image{
    width: 35%;
    height: auto;
    min-width: 200px;
    flex-grow: 1;
    margin: 5px;
}
