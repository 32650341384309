// Some good colors for boxy layouts
$containerColor: rgba(106, 121, 134, 0.1);
$blockColor: rgb(156, 205, 215);
$blockFontColor: rgb(45, 10, 85);
$blockActiverColor: rgb(43, 159, 128);

//Color for the center div on every page
$centerColor: rgb(245, 245, 245);

//color for the header and footer
$hfColor: rgb(240, 240, 240);

//Main background color
$masterBackgroundColor: rgb(31, 34, 83)
